import { default as indexH7OltNuNIPMeta } from "/home/lessmess/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index0dKVtBnYB5Meta } from "/home/lessmess/www/frontend/pages/board/index.vue?macro=true";
import { default as gtm7eZHN8oD6KMeta } from "/home/lessmess/www/frontend/pages/gtm.vue?macro=true";
import { default as onboardingNU1S9RrjIPMeta } from "/home/lessmess/www/frontend/pages/mail/onboarding.vue?macro=true";
import { default as indexT5JvK8mD4TMeta } from "/home/lessmess/www/frontend/pages/start/index.vue?macro=true";
import { default as indexQmZYYFzwOMMeta } from "/home/lessmess/www/frontend/pages/user/login/index.vue?macro=true";
import { default as indexRSOIfIC65UMeta } from "/home/lessmess/www/frontend/pages/user/register/index.vue?macro=true";
import { default as onboardingxgZEJsxsD9Meta } from "~/pages/mail/onboarding.vue?macro=true";
export default [
  {
    name: indexH7OltNuNIPMeta?.name ?? "slug",
    path: indexH7OltNuNIPMeta?.path ?? "/:slug(.*)*",
    meta: indexH7OltNuNIPMeta || {},
    alias: indexH7OltNuNIPMeta?.alias || [],
    redirect: indexH7OltNuNIPMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index0dKVtBnYB5Meta?.name ?? "board",
    path: index0dKVtBnYB5Meta?.path ?? "/board",
    meta: index0dKVtBnYB5Meta || {},
    alias: index0dKVtBnYB5Meta?.alias || [],
    redirect: index0dKVtBnYB5Meta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/board/index.vue").then(m => m.default || m)
  },
  {
    name: gtm7eZHN8oD6KMeta?.name ?? "gtm",
    path: gtm7eZHN8oD6KMeta?.path ?? "/gtm",
    meta: gtm7eZHN8oD6KMeta || {},
    alias: gtm7eZHN8oD6KMeta?.alias || [],
    redirect: gtm7eZHN8oD6KMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/gtm.vue").then(m => m.default || m)
  },
  {
    name: onboardingNU1S9RrjIPMeta?.name ?? "mail-onboarding",
    path: onboardingNU1S9RrjIPMeta?.path ?? "/mail/onboarding",
    meta: onboardingNU1S9RrjIPMeta || {},
    alias: onboardingNU1S9RrjIPMeta?.alias || [],
    redirect: onboardingNU1S9RrjIPMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/mail/onboarding.vue").then(m => m.default || m)
  },
  {
    name: indexT5JvK8mD4TMeta?.name ?? "start",
    path: indexT5JvK8mD4TMeta?.path ?? "/start",
    meta: indexT5JvK8mD4TMeta || {},
    alias: indexT5JvK8mD4TMeta?.alias || [],
    redirect: indexT5JvK8mD4TMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmZYYFzwOMMeta?.name ?? "user-login",
    path: indexQmZYYFzwOMMeta?.path ?? "/user/login",
    meta: indexQmZYYFzwOMMeta || {},
    alias: indexQmZYYFzwOMMeta?.alias || [],
    redirect: indexQmZYYFzwOMMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexRSOIfIC65UMeta?.name ?? "user-register",
    path: indexRSOIfIC65UMeta?.path ?? "/user/register",
    meta: indexRSOIfIC65UMeta || {},
    alias: indexRSOIfIC65UMeta?.alias || [],
    redirect: indexRSOIfIC65UMeta?.redirect || undefined,
    component: () => import("/home/lessmess/www/frontend/pages/user/register/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingxgZEJsxsD9Meta?.name ?? "mail-onboarding",
    path: onboardingxgZEJsxsD9Meta?.path ?? "/mail/:type/:smirchId/:rayonId/:uid/:token",
    props: true,
    meta: onboardingxgZEJsxsD9Meta || {},
    alias: onboardingxgZEJsxsD9Meta?.alias || [],
    redirect: onboardingxgZEJsxsD9Meta?.redirect || undefined,
    component: () => import("~/pages/mail/onboarding.vue").then(m => m.default || m)
  }
]